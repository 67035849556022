/*
* Layout based components
*/

//
// Styling for any classes that will be reused through the site should be included in the file
//

.viewport-screen {
  // Will use 100vh as fallback option
  min-height: 100vh;
  // Viewport height for mobile & desktop browser nav ui
  min-height: calc(var(--vh, 1vh) * 100);
}

.btn-anim {
  @apply hover:bg-opacity-70 transition ease-in-out duration-500;
}

// Stage Indicator Styling

.indicator-list {
  margin-right: 25px;
  position: relative;

  &.complete {
    @apply bg-blue bg-opacity-30;
  }

  &:not(:nth-child(5)) {
    &:after {
      content: "";
      width: 25px;
      height: 1px;
      background: #ededed;
      position: absolute;
      left: 100%;
      z-index: -10;
    }
  }
}

select,
input,
textarea {
  color: rgba(0, 0, 0, 0.5);
  @apply appearance-none;
}

select {
  background-image: url(../../src/assets/images/form-select-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 12px top 50%, 0 0;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  color: #4299e1;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
}

input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}