@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

// SCSS Setup Import Structure

// Base Stylings
@import "reset";
@import "fonts";
@import "layout";
@import "typography";
@import "loading-state";

button:disabled {
    opacity: .5;
}

.video-wrapper {
    width: 100%;
    position: relative;
    padding: 56.25% 0 0;

    iframe {
        position: absolute;
        width: 100%; height: 100%;
        top: 0; left: 0;
    }
}

.pound-form-wrapper {
    position: relative;

    &:before {
        content: '£';
        position: absolute;
        top: 50%;
        line-height: 0;
        opacity: .7;
        left: 8px;
    }
}