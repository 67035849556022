/*
* Global resets Styles
* Referenced from https://github.com/andreasantonsson/scss-setup
*/

// Uses border-box globally as well as to remove margins & paddings for all elements
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: inherit;
}

// Ensures that the webpage always fill the entire height of the viewport
html {
  min-height: 100%;
}

// Body
// 1. Applys a default background colour
// 2. Applys a default colour on webpage type content
// 3. Prevents any adjustments to the font size when changing orientation with IE, Windows & iOS devices
body {
  background-color: #ffffff; // 1
  color: #000000; // 2
  text-align: left;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%; // 3
  text-rendering: optimizeLegibility;
}

//
// Navigation & list style
//

// Default list styling isn't desired for the navigation
nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul {
  list-style: none;
}

//
// Embedded Elements
//

// 1. Block display is usually what we want
// 2. Responsive by default
// 3. Remove max-width if a `width` and/or `height` attribute have been explicitly defined.
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block; // 1
  max-width: 100%; // 2

  &[height],
  &[width] {
    max-width: none; // 3
  }
}

// Maintain intrinsic aspect ratios when `max-width` is applied
// (iframe, embed, and object have no intrinsic ratio, set height explicitly)
canvas,
img,
svg,
video {
  height: auto;
}

img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}

// 1. SVG elements should fallback to their surrounding text color.
// 2. Workaround for the SVG overflow bug in IE10/11 is still required.
svg {
  fill: currentColor; // 1
  vertical-align: middle; // 2
  overflow: hidden; // 2
}
