.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.loading-indicator {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loading-indicator div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgba(114, 215, 244, 1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .loading-indicator div:nth-child(1) {
    left: 8px;
    animation: loading-indicator1 0.6s infinite;
  }
  .loading-indicator div:nth-child(2) {
    left: 8px;
    animation: loading-indicator2 0.6s infinite;
  }
  .loading-indicator div:nth-child(3) {
    left: 32px;
    animation: loading-indicator2 0.6s infinite;
  }
  .loading-indicator div:nth-child(4) {
    left: 56px;
    animation: loading-indicator3 0.6s infinite;
  }
  @keyframes loading-indicator1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loading-indicator3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes loading-indicator2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  